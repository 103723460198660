import { Component } from "@angular/core";
import { AppService } from "@core/app/app.service";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

@Component({
	selector: "cm-footer",
	template: `
		<div class="bg-success">
			<div class="container-fluid py-3 p-lg-5">
				<div class="row align-items-stretch justify-content-center mx-auto">
					<div class="col-12 col-lg-6 col-xl-5 py-2">
						<div class="row align-items-center">
							<div class="col-12 col-xl-6 text-center">
								<a routerLink="/">
									<img
										[src]="app.logo$ | async | image : 'm'"
										alt="{{ app.dealer$ | async }}"
										class="logo img-fluid my-2"
									/>
								</a>
							</div>
							<div class="col-12 col-xl-6 d-flex flex-column align-items-center justify-content-center">
								<div class="row" style="width: 235px; max-width:95vw;">
									<div class="col-3">
										<img
											src="https://dms.rvimg.com/s3static/52/icon-pin-white.png"
											alt="pin"
											class="img-fluid"
										/>
									</div>
									<div class="col-9">
										<ng-container *ngFor="let location of app.locationAddresses$ | async">
											<a
												[href]="location.link"
												class="address text-white d-block font-weight-bold"
											>
												{{ location.addressLine1 }}<br />{{ location.addressLine2 }}
											</a>
											<a
												href="tel:{{ location.phoneNumber }}"
												class="address text-white d-block font-weight-bold mb-3"
											>
												{{ location.phoneNumber }}
											</a>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="d-none d-xl-block col-xl-3"></div>
					<div class="col-12 col-lg-6 col-xl-4 d-flex flex-column justify-content-between">
						<div class="row flex-wrap justify-content-around justify-content-xl-start py-2">
							<div class="col-12 col-lg-4 text-center text-lg-left">
								<a routerLink="/products" class="text-white pb-2 d-block">PRODUCTS</a>
								<a routerLink="/equipment" class="text-white pb-2 d-block">Filler Machines</a>
								<a routerLink="/products" fragment="attachment" class="text-white pb-2 d-block"
									>Attachments & Equipment</a
								>
								<a routerLink="/products" fragment="fabric" class="text-white pb-2 d-block"
									>Sock Fabric</a
								>
							</div>
							<div class="col-12 col-lg-4 text-center text-lg-left">
								<a routerLink="/resources" class="text-white pb-2 d-block">RESOURCES</a>
								<a
									routerLink="/resources"
									[queryParams]="{ activeId: 'installation' }"
									class="text-white pb-2 d-block"
									>Silt Sock Installation</a
								>
								<a
									routerLink="/resources"
									[queryParams]="{ activeId: 'maintenance' }"
									class="text-white pb-2 d-block"
									>Care & Maintenance</a
								>
								<a
									routerLink="/resources"
									[queryParams]="{ activeId: 'operation' }"
									class="text-white pb-2 d-block"
									>Filler Operation</a
								>
								<a
									routerLink="/resources"
									[queryParams]="{ activeId: 'specs' }"
									class="text-white pb-2 d-block"
									>Fabric Specs</a
								>
							</div>
							<div class="col-12 col-lg-4 text-center text-lg-left">
								<a routerLink="/contact" class="text-white pb-2 d-block">CONTACT US</a>
							</div>
						</div>
					</div>
				</div>
				<div class="row flex-wrap justify-content-center py-2">
					<div class="col-12 d-flex flex-wrap justify-content-center text-white my-2">
						<a class="text-white text-nowrap" routerLink="/terms-of-service">Terms of Service</a
						><span class="mx-1">|</span>
						<a class="text-white text-nowrap" routerLink="/privacy-policy">Privacy Policy</a
						><span class="mx-1">|</span>
						<a class="text-white text-nowrap" href="//designforcemarketing.com" target="_blank"
							>Powered by Design Force Marketing</a
						>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			.rounded-button {
				border-radius: 1.5rem !important;
			}
			.footer-img {
				margin: 0;
				padding: 0;
				vertical-align: top;
			}
		`,
	],
})
export class FooterComponent {
	constructor(public app: AppService) {}
	faFacebook = faFacebook;
}
